<i18n>
{
  "de": {
    "pageTitle": "Portfolio hinzufügen",
    "portfolioOptionsLabel": "Portfolio",
    "loading": "Laden…",
    "submitButtonLabel": "Speichern",
    "roleLabel": "Berechtigung",
    "userNotFound": "Benutzer nicht gefunden",
    "roleLabels": {
      "USER": "USER",
      "MANAGER": "MANAGER"
    }
  }
}
</i18n>

<template>
  <MainLayout :ready="allPortfoliosReady && allUsersReady" class="admin-add-portfolio-to-user-page">
    <template #default>
      <p v-if="error">{{ JSON.stringify(error) }}</p>
      <h1>{{ $t('pageTitle') }}</h1>
      <form>
        <div class="form-field">
          <label for="portfolio-options">{{ $t('portfolioOptionsLabel') }}</label>
          <div>
            <Select
              id="portfolio-options"
              v-model="portfolioId"
              clearable
              :options="portfolioOptions"
              :reduce="(portfolio) => portfolio.id"
            />
          </div>
        </div>

        <div class="form-field">
          <label for="role-options">{{ $t('roleLabel') }}</label>
          <div>
            <v-select id="role-options" v-model="role" clearable :options="roleOptions" :reduce="(role) => role.key" />
          </div>
        </div>

        <ButtonWrapper>
          <button type="button" class="button" :disabled="!submitButtonEnabled" @click="onSubmit">
            {{ $t('submitButtonLabel') }}
          </button>
        </ButtonWrapper>
      </form>
    </template>
  </MainLayout>
</template>

<script>
import { mapActions } from 'vuex'

import MainLayout from '@/pages/layouts/MainLayout.vue'
import AllPortfoliosMixin from '@/pages/vuex-mixins/AllPortfoliosMixin.vue'
import AdminAllUsersMixin from '@/pages/vuex-mixins/AdminAllUsersMixin.vue'
import AdminUserMixin from '@/pages/vuex-mixins/AdminUserMixin.vue'

import ButtonWrapper from '@/components/shared/ButtonWrapper.vue'
import Select from '@/components/shared/forms/Select.vue'

export default {
  name: 'adminAddPortfolioToUser',

  mixins: [
    AllPortfoliosMixin, // Provides: portfolios, allPortfoliosReady
    AdminAllUsersMixin, // Provides: allUsers, allUsersReady
    AdminUserMixin, // Provides: user
  ],

  components: {
    ButtonWrapper,
    MainLayout,
    Select,
  },

  data() {
    return {
      role: null,
      portfolioId: null,
      error: null,
    }
  },

  computed: {
    roleOptions() {
      return [
        {
          key: 'USER',
          label: this.$t('roleLabels.USER'),
        },
        {
          key: 'MANAGER',
          label: this.$t('roleLabels.MANAGER'),
        },
      ]
    },

    userPortfolios() {
      return this.user && this.user.portfolios
    },

    portfolioOptions() {
      const portfolios = this.portfolios || []
      const existingPortfolios = this.userPortfolios || []
      return portfolios
        .filter((portfolio) => !existingPortfolios.find((ep) => ep.id === portfolio.id))
        .map((portfolio) => {
          return { label: portfolio.name, id: portfolio.id }
        })
    },

    submitButtonEnabled() {
      return Boolean(this.portfolioId) && this.role !== null
    },
  },

  methods: {
    ...mapActions({
      addPortfolioUser: 'portfolio/addPortfolioUser',
    }),

    async onSubmit() {
      try {
        this.error = null
        await this.addPortfolioUser({ portfolioId: this.portfolioId, userId: this.user_id, role: this.role })
        this.$router.push({ name: 'adminUser', params: { user_id: this.user_id } })
      } catch (error) {
        this.error = error
      }
    },
  },

  head() {
    return {
      title: () => {
        return { inner: this.$t('pageTitle') }
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.admin-add-portfolio-to-user-page {
  & form {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-m);
    align-items: stretch;
    justify-content: flex-start;

    & .form-field {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: stretch;
      gap: var(--spacing-m);

      & label {
        width: 160px;
      }

      & > div {
        width: 260px;
      }
    }

    & button {
      grid-column-end: -1;
      justify-self: start;
    }
  }
}
</style>
